<template>
  <v-container grid-list-xl fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Select Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @input="menuDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          v-model="selectedNotifications"
          :headers="headers"
          :items="items"
          dense
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <!-- <td class="text-center">
                  <v-checkbox
                    v-model="selectedNotifications"
                    class="mt-0 pt-0"
                    color="primary"
                    hide-details
                    :value="item"
                  ></v-checkbox>
                </td> -->
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-no-wrap">
                  {{
                    item.alarm_type === 0 && item.alarm_status === 1
                      ? "ENGINE ON ALERT"
                      : item.alarm_type === 0 && item.alarm_status === 0
                      ? "ENGINE OFF ALERT"
                      : item.alarm_type === 1
                      ? "CIRCUIT CUT-OFF ALERT"
                      : item.alarm_type === 2
                      ? "PANIC BUTTON ALERT"
                      : item.alarm_type === 3
                      ? "OVERSPEEDING ALERT"
                      : item.alarm_type === 4
                      ? "IDLE ALERT"
                      : item.alarm_type === 5
                      ? "PITSTOP ALERT"
                      : item.alarm_type === 6
                      ? "GEOZONE ALERT"
                      : ""
                  }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ $store.state.company.name.toUpperCase() }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{
                    item.alarm_type === 0 ||
                    item.alarm_type === 3 ||
                    item.alarm_type === 4 ||
                    item.alarm_type === 5 ||
                    item.alarm_type === 6
                      ? "SAFETY"
                      : item.alarm_type === 1 || item.alarm_type === 2
                      ? "SECURITY"
                      : ""
                  }}
                </td>
                <td class="text-no-wrap text-center">
                  {{ item.datetimestamp }}
                </td>
                <td class="text-no-wrap">
                  {{ item.plate_no ? item.plate_no : "--" }}
                </td>
                <td class="text-no-wrap">{{ item.location }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Services from "@/services/CompanyServices";

export default {
  data: () => {
    return {
      title: "Notifications",
      items: [],
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Alert Type", sortable: false },
        { text: "Company", sortable: false },
        { text: "Type", sortable: false },
        { text: "Time", sortable: false },
        { text: "Plate No", sortable: false },
        { text: "Location", sortable: false },
      ],
      queryParams: {},
      data: {},
      confirmLoading: false,
      date: new Date().toISOString().substr(0, 10),
      menuDate: false,
      selectedNotifications: [],
    };
  },
  created: function() {
    if (this.$store.state.token !== null) {
      this.getData();

      setInterval(() => {
        this.updateData();
      }, 1000 * 60);
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  watch: {
    date() {
      this.getData();
    },
  },
  methods: {
    async getData() {
      this.$store.dispatch("startLoading");

      this.queryParams.company_id = this.$store.state.company.id;

      this.queryParams.date_time_from = this.$dateFormat(
        this.date,
        "YYYY-MM-DD"
      );

      this.queryParams.date_time_to = this.$dateFormat(this.date, "YYYY-MM-DD");

      try {
        let response = (await Services.getNotifications(this.queryParams)).data;

        this.items = response.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    async updateData() {
      this.queryParams.company_id = this.$store.state.company.id;

      this.queryParams.date_time_from = this.$dateFormat(
        this.date,
        "YYYY-MM-DD"
      );

      this.queryParams.date_time_to = this.$dateFormat(this.date, "YYYY-MM-DD");

      try {
        let response = (await Services.getNotifications(this.queryParams)).data;

        this.items = response.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style></style>
